/* @import '~antd/dist/antd.css'; */
@import '~antd/dist/antd.dark.less';
/* @import '~antd/dist/antd.compact.css'; */

/* @import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Karla&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Inconsolat&display=swap'); */

:root {
  --c-white: #ffffff;
  --c-gray-500: #999999;
  --c-gray-800: #666666;
  --c-gray-900: #444444;

  --c-blue: #0084FF;
  --c-teal: #44BEC7;
  --c-yellow: #FFC300;
  --c-red: #FA3C4C;

  --c-bg: var(--c-gray-800);
  --c-instrument-bg: var(--c-gray-900);
  --c-instrument-active: var(--c-white);

  --c-btn: var(--c-white);
  --c-btn-disabled: var(--c-gray-500);
  --c-btn-bg: var(--c-gray-900);

  --c-accent-blue: var(--c-blue);
  --c-accent-teal: var(--c-teal);
  --c-accent-yellow: var(--c-yellow);
  --c-accent-red: var(--c-red);
  --c-accent-white: var(--c-white);
  --c-accent-gray: var(--c-gray-500);
  --c-accent-black: var(--c-gray-900);
}

html {
  width: 100vw;
  height: 100%;
  position: fixed;
  overflow: hidden;
  overscroll-behavior: none;
  /* pointer-events: none; */
  --antd-wave-shadow-color: #fff !important;
  scroll-behavior: smooth;
}

body {
  color: #ffffff;
  position: relative;
  overflow: hidden;
  font: 16px 'Karla';
  /* pointer-events: none; */
}

.site-custom-tab-bar {
  /* z-index: 9999; */
  background: yellow;
}

// .antd-modal-header,
// .ant-modal-close-x {
//   display: none;
// }
.ant-modal-footer{
  border-top: none;
  padding: 0 16px 16px 16px;
}

.ant-popover-inner-content {
  padding: 0;
}
.ant-popover-inner {
  background: transparent;
  box-shadow: none;
}

* {
  // TODO: Handle css user-select properly
  user-select:none;
  -webkit-touch-callout:none;
}
/* TODO: QUESTION: Why so important? HALP */
/* *:focus {
  border-color: none !important;
  outline: none !important;
  box-shadow: none !important;
}
/**:active {
  border-color: none !important;
  outline: none !important;
  box-shadow: none !important;
  /* color: currentColor !important; */
/* } */

/* .ant-btn:active,
.ant-btn:hover,
.ant-btn.active {
  color: inherit !important;
  border-color: none !important;
} */

.ant-carousel .slick-dots-bottom {
  bottom: 0 !important;
}
.ant-carousel .slick-dots li {
  width: 8px !important;
}
.ant-carousel .slick-dots li button {
  background: #ffffff !important;
}


// .ant-popover.popconfirm .ant-popover-inner-content {
//   flex-direction: row !important;
// }
// .ant-popover.popconfirm .ant-popover-message {
//   display: none;
// }
// .ant-popover.popconfirm
//   .ant-popover-content
//   .ant-popover-inner
//   .ant-popover-inner-content
//   .ant-popover-buttons
//   button {
//   margin: 0 8px !important;
// }
// .ant-popover.popconfirm .ant-popover-content .ant-popover-arrow {
//   z-index: 9999 !important;
//   border-color: #000 !important;
// }

// .ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow {
//   display: none;
// }

// .ant-popover.popconfirm
//   .ant-popover-content
//   .ant-popover-inner
//   .ant-popover-inner-content {
//   padding: 0;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   background: black;
//   padding: 1em;
// }

.ant-input-group-addon:first-child {
  width: 110px;
  text-align: left;
}
.ant-divider-horizontal.ant-divider-with-text-left::before {
  width: 0;
}
.ant-divider-plain.ant-divider-with-text {
  font-size: 80%;
  text-transform: uppercase;
  margin: 24px 0 8px 0;
  color: rgba(255, 255, 255, .5);
}
.ant-divider-inner-text {
  display: inline-block;
  padding: 0 1em 0 0.2em;
}

.ant-collapse-content-box {
  padding: 0px 0 24px 0 !important;
}
.ant-collapse-header {
  padding: 8px 0 8px 16px !important;
}
.ant-collapse-borderless {
  background-color: transparent;
}
.ant-collapse-item {
  border: none !important;
}
.ant-collapse-arrow {
  left: 0 !important;
}

.ant-input,
.ant-input-affix-wrapper,
.ant-input-affix-wrapper-focused {
  user-select:auto;
  caret-color: #ffffff;
  border-color: rgba(255, 255, 255, 0.1) !important;
  border-radius: 0 !important;
  // font-family: 'IBM Plex Mono';
  &:focus {
    border-color: rgba(255, 255, 255, 0.5) !important;
  }
}
.ant-input-group-addon {
  border-radius: 0px !important;
}

.ant-tabs-nav {
  color: rgba(255, 255, 255, 0.35);
}
.ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-nav .ant-tabs-tab:hover {
  color: rgba(255, 255, 255, 0.65) !important;
}

/* .ant-btn:hover,
.ant-btn:focus {
  border-color: inherit !important;
  color: inherit !important;
  background-color: inherit !important;
}
button.ant-btn.ant-btn-link.ant-btn-circle.ant-btn-icon-only {
  border: none !important;
} */

#main {
  height: 100%;
}

.displayBlock {
  display: block !important;
}

.displayNone {
  display: none !important;
}

.edit-cancel,
.edit-check,
.edit-check~div {
  // display: none;
  font-size: 14px !important;
  margin-right: 2px !important;
}

.edit-check~div {
  display: none;
}

textarea.variable-editor,
textarea.variable-editor:focus {
  padding: 0px 6px !important;
  height: 24px !important;
  border: none;
  border-bottom: 1px solid #ffffff;
  resize: none;
  font-size: '13px' !important;
  font-family: 'IBM Plex Mono' !important;
}

.click-to-edit {
  display: none !important;
  position: absolute;
  right: 0;
  width: 20px;
  height: 20px;
  // background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><linearGradient id='gradient'><stop offset='10%' stop-color='%23F00'/><stop offset='90%' stop-color='%23fcc'/> </linearGradient><rect fill='url(%23gradient)' x='0' y='0' width='100%' height='100%'/></svg>");
}
.click-to-edit-icon > svg {
  fill: transparent;
  border: 1px solid white;
  background: white;
  border-radius: 50%;
  margin: 5px 5px 0px 0px !important;
  height: 5px !important;
  width: 5px !important;
}

// .variable-value > div {
//   color: rgba(255,255, 255, 0.20) !important;
//   padding: 1px 4px !important;
//   background-color: transparent !important;
//   border: 1px solid rgba(255,255, 255, 0.20) !important;
// }

.variable-editor,
.variable-editor:focus {
  background-color: transparent !important;
  outline: none !important;
  padding: 5px 10px !important;
  font-family: 'Karla' !important;
}

.react-draggable {
  width: 100%
}


// .sketch-picker {
//   transform: scale(1.25) translate(-25%, -5%);
// }
input.ant-input.input-color {
  border: none;
}
.ant-popover.color-popover-overlay {
  z-index: 9999;
}
.ant-popover.color-popover-overlay .ant-popover-content .ant-popover-arrow{
  // border-right-color: #ffffff;
  // border-bottom-color: #ffffff;
  display: none;
}
.sketch-picker {
  width: 280px !important;
}
.sketch-picker > .flexbox-fix:last-child > div {
  width: 24px !important;
  height: 24px !important;
  margin: 0 12px 12px 0 !important;
}
.sketch-picker > .flexbox-fix:nth-child(3) {
  display: none !important;
}
.sketch-picker > .flexbox-fix:nth-child(2) > div:nth-child(1) > :nth-child(2) {
  display: none !important;
}
.sketch-picker > .flexbox-fix:nth-child(2) > div:nth-child(2) {
  display: none !important;
}
.sketch-picker > .flexbox-fix:nth-child(4) {
  border-top: none !important;
  padding: 5px 0px 0px 10px !important;
}
.sketch-picker > .flexbox-fix:nth-child(2) > div:nth-child(1) > :nth-child(1) {
  height: 24px !important;
}

.sketch-picker > .flexbox-fix:nth-child(2) > div:nth-child(1) > :nth-child(1) > :nth-child(1) > :nth-child(1) > :nth-child(2) {
  height: 24px !important;
}
.sketch-picker > .flexbox-fix:nth-child(2) > div:nth-child(1) > :nth-child(1) > :nth-child(1) > :nth-child(1) > :nth-child(2) > :nth-child(1) {
  height: 22px !important;
}

.ant-select-item-option-selected {
  background-color: #1f1f1f !important;
}
@primary-color: #555555;